import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Card from "../components/Card";
import Title from "../components/Title";
import { Helmet } from "react-helmet";
import SEO from "../components/SEO";

export const query = graphql`
  query($slug: String) {
    allSanityService(
      sort: { fields: order }
      filter: { category: { slug: { current: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          name
          slug {
            current
          }
          description
          image {
            asset {
              fixed(height: 300, width: 300) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
  }
`;

const CategoryPage = ({ data, pageContext }) => {
  const { edges } = data.allSanityService;
  const seoName =
    pageContext.title === "Other Services" ? "Other" : pageContext.title;
  const seoNameNormalized =
    seoName.charAt(seoName.length - 1) === "s" ? seoName.slice(0, -1) : seoName;
  const seoTitle = `CLIPS Hair Studio | ${seoNameNormalized} Services`;
  const seoDesc = `We offer a full range of ${seoNameNormalized} services`;

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDesc}
        url={`http://www.clips-hairstudio.com/services/${pageContext.slug}/`}
        image={pageContext.image}
      />
      <Helmet>
        <title>CLIPS Hair Studio | {pageContext.title}</title>
      </Helmet>
      <CategoryContent>
        <Title>{pageContext.title}</Title>
        <Grid>
          {edges.map(({ node }) => (
            <Card
              key={node.id}
              to={`/service/${node.slug.current}/`}
              title={node.name}
              description={`${node.description.substring(0, 90)}...`}
              image={node.image.asset.fixed}
              cta="Learn More"
            />
          ))}
        </Grid>
      </CategoryContent>
    </>
  );
};

export default CategoryPage;

const CategoryContent = styled.div`
  position: relative;
  top: var(--nav-height);
  padding-bottom: 2rem;
`;

const Grid = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: center;
  grid-gap: 2rem;
`;
